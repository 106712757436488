<script>
import MasterDetail from "@/components/master-detail.vue";
import { mapGetters } from 'vuex';

export default {
  components: {
    MasterDetail
  },
  computed: {
    ...mapGetters(['clientId']),
    columns() {
      return [
        {
          key: "n",
          name: "Nº",
          align: 1,
        },
        {
          key: "incentivo",
          name: "Nome Incentivo"
        },
        {
          key: "valor_IRPJ",
          name: "Valor IRPJ",
        },
        {
          key: "valor_CSLL",
          name: "Valor CSLL",
        }
      ];
    },
    customResource: function() {
      const toCurrency = this.$options.filters.toCurrency;
      const setTrimestral = (value) => this.trimestral = value;
      const self = this;
      const resource = this.apiResource(`/v1/clientes/${self.clientId}/lalur`);
      
      return {
        async get({ query }) {                   
          const response = await resource.get({ query });

          const { trimestral, itens } = response ?? {};
          setTrimestral(!!trimestral);

          const totalIrpj = itens?.reduce((sum, item) => sum + (item.irpj || 0), 0) || 0;
          const totalCsll = itens?.reduce((sum, item) => sum + (item.csll || 0), 0) || 0;


          return [
            "Dispêndios como Despesa Operacional",
            "Redução de 50% de IPI",
            "Depreciação Acelerada Integral no Ano de Aquisição",
            "Amortização Acelerada de Bens Intangíveis",
            "Universidades, Instituições de Pesquisa e Inventores Independentes",
            "Transferências a Micro e Pequenas Empresas",
            "Transferências a Inventor Independente",
            "Dispêndios - Adicional de 60 a 80%", // index === 7
            "Patentes e Cultivares - Adicional de 20%",
            "Instituições Científicas e Tecnológicas - ICT e Entidades Científicas e Tecnológicas Privadas, sem Fins Lucrativos",
            "Depreciação Acelerada Vinculada a Projetos",
            "Amortização Acelerada de Instalações Fixas",
            "Subvenções Governamentais da União",
            "Atividades de Informática e Automação"
          ].map((label, index) => ({
            n: 30 + index,
            incentivo: `INOVAÇÃO TECNOLÓGICA - ${label}`,
            valor_IRPJ: index === 7 ? toCurrency(totalIrpj) : "",
            valor_CSLL: index === 7 ? toCurrency(totalCsll) : ""
          }));
        }
      };
    }
  },
  data: function() {
    return {
      opts: {},
      trimestral: false,
    };
  }
};
</script>

<template>
  <!-- TODO caso seja necessárioinserir a segunda linha de cabeçalho indicando o trimestre ou "Somatório do Ano" provavelmente deve trocar MasterDetail por v-data-table -->
  <MasterDetail
    class="master-detail"
    :cols="columns"
    :customResource="customResource"
    disableContextMenu
    disablePagination
    :opts="opts"
    :hasOneQuarterFilter="trimestral"
    :hasYearFilter="!trimestral"
    :canDelete="false"
    :canEdit="false"
    :hasExportCSV="false"
    :hasNewButton="false"
  ></MasterDetail>
</template>

<style lang="scss" scoped>
.master-detail {
  &,
  &::v-deep .v-data-table__wrapper {
    height: 100% !important;
  }
}
</style>
